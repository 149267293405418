import { getRequest, postRequest } from "core/api/base";
import { GENERAL_TOKEN, PROFILE_TOKEN } from "core/constants";

export class MessageService {
  static getThemes = () => getRequest({ url: "/rest/suggestions/", token: GENERAL_TOKEN });
  static send = message => {
    const profileToken = localStorage.getItem(PROFILE_TOKEN);
    const messageWithProfileToken = { ...message, profile_token: profileToken };
    postRequest({ url: "/rest/suggestions/", data: messageWithProfileToken, token: GENERAL_TOKEN });
  };
}
