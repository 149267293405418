import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useSearchParams } from "react-router-dom";
import { FREE_PLAN } from "core/constants";
import { createCustomerObject } from "core/store/entities/User/user.actions";
import * as yup from "yup";
import { userSelectors } from "core/store/entities/User/user.selectors";

const schema = yup.object({ email: yup.string().required("This field is required") });

export const useCreateAccountStep1 = () => {
  const apiErrors = useSelector(userSelectors.errors);
  const isSubmitting = useSelector(userSelectors.isSignUpSubmitting);
  const {
    control,
    handleSubmit,
    formState: { errors: formErrors }
  } = useForm({
    resolver: yupResolver(schema)
  });
  const dispatch = useDispatch();
  const { plan } = useParams();
  const [searchParams] = useSearchParams();

  const errors = { ...apiErrors, ...formErrors };

  const fetchSelectedPlan = () => {
    if (plan === FREE_PLAN) {
      return FREE_PLAN;
    } else {
      return searchParams.get("plan");
    }
  };

  const onSubmit = ({ email }) => {
    if (isSubmitting) {
      return;
    } else {
      const plan = fetchSelectedPlan();
      dispatch(createCustomerObject({ email, plan }));
    }
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    errors
  };
};
