import React from "react";

import { Header, InfoBlock, Spinner } from "ui/components";
import { Grid, Link, Typography } from "@mui/material";
import { useConnectAPI } from "./useConnectAPI";
import { generalContentPadding } from "ui/components/Layout";

export const ConnectAPI = () => {
  const { webInfo, isWebInfoLoading, footer } = useConnectAPI();

  if (isWebInfoLoading || !webInfo) return <Spinner />;

  return (
    <>
      <Header title="Connect through our Web API" />
      <Grid container rowSpacing={2} sx={{ padding: generalContentPadding }}>
        <Grid item xs={12} lg={8} xl={8}>
          <Typography variant="bodyLBolder">
            Use your API token to connect through our Web API to send emails or to get statistics
            like bounces and complaints
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8} xl={8}>
          <InfoBlock item={webInfo} footerContent={footer} />
        </Grid>
        <Grid item xs={12} lg={8} xl={8}>
          <Link href="https://inboxroad.com/documentation/api-reference/" target="_blank">
            Link to the docs about how to setup the inboxroad API
          </Link>
        </Grid>
      </Grid>
    </>
  );
};
